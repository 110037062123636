import React from "react"
// import { footer } from "../../data/Data"
import "./footer.css"
import { useHistory } from "react-router-dom";
import {useEffect} from 'react';
// import { nav } from "../../data/Data"


const Footer = () => {
  const history = useHistory();
  const contactPage = () => {
    history.push("/contact-us")
  }
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);
  const url = 'https://www.facebook.com/dialageekca';
  const url2 = 'https://ca.linkedin.com/company/dial-a-geek-consulting-inc';
  const url3 = 'https://www.totalsupportsolutions.ca/';
  const url4 = 'https://www.tektraining.ca/';



  return (
    <>
      <section className='footerContact'>
        <div className='container'>
          <div className='send flex'>
            <div className='text'>
              <h1 className='reduce'>Do you have questions?</h1>
              <p>Contact us today to find out more.</p>
            </div>
            <button onClick={() => {
                      window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                      contactPage();
                    }} className='btn5'>Contact Us</button>
          </div>
        </div>
      </section>

      <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
              <img src='../images/logo.png' alt='' />
              <h3>Tech Support Delivered Quickly</h3>
              {/* <p> IT Solutions since 2003.<br/></p> */}
              <a href='tel:(+1)250-385-3425'><i class="fa fa-thin fa-square-phone"></i></a>
              <a href='tel:(+1)250-385-3425'><small> (+1)</small> 250-385-3425</a>
              
              <div className='social-icons-container'>
                <div className='pointer'><i class="fa-brands fa-facebook-square" onClick={() => window.open(url, '_blank')}></i></div>
              <div className='pointer'><i class="fa-brands fa-linkedin" aria-hidden="true" onClick={() => window.open(url2, '_blank')}></i></div>
              </div>
              {/* <div className='input flex'>
                
                <input type='text' placeholder='Your Email...' />
                <div className='footerbutton'>
                <button>Subscribe</button>
                </div>
              </div> */}
              
            </div>
          </div>
            <div className='box'>
              <h3 className='underline'>Links</h3>
              {/* <ul>
                          {nav.map((list, index) => (
                            <li className='listitem' key={index}>
                              <Link to={list.path}>{list.text}</Link>
                            </li> 
                          ))}
              </ul> */}
              <ul className='footer-links'>
                <li><a className='whitetext' href='/'>Home</a></li>
                <li><a className='whitetext' href='/about'>About</a></li>
                <li><a className='whitetext' href='/services'>Services</a></li>
                <li><a className='whitetext' href='/contact-us'>Contact</a></li>
                <li><a className='whitetext' href='/rates'>Rates</a></li>
                <li><a className='whitetext' href='/terms'>Terms</a></li>
              </ul>
              
            </div>
            <div className='box'>
              <h3 className='underline'>Resources</h3>
              <ul className='footer-links'>
                <li><a className='whitetext' href={url3}>Helpdesk</a></li>
                <li><a className='whitetext' href={url4}>Training</a></li>
              </ul>
              
            </div>
        </div>
      </footer>
      <div className='legal'>
        <span className='copyright'>© 2024 Dial-A-Geek | All Rights Reserved</span>
      </div>
    </>
  )
}

export default Footer
