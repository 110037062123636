import React from "react"
import Back from "../common/Back"
import GoToTop from "../common/GoToTop"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./service5.css"
import { useHistory } from "react-router-dom";

const Service5 = () => {
  const url = 'https://bookit.dialageek.ca/';
  const history = useHistory();
  const routeChange = () =>{ 
    let path = `/services`; 
    history.push(path);
  }
  return (
    <>
      <section className='about'>
        <Back name='Network Monitoring' title='Get alerted quickly' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
          <div className='backbuttoncontainer'>
              <button className='backbutton' onClick={routeChange}>
              <p><i class="fa-solid fa-circle-arrow-left fa-2xl"></i><span className='backbuttontext'>Back to Services</span></p>
              </button>
            </div>
            <Heading title='Managed Services' subtitle='Network monitoring & management' />
            <p className='backupText'>Is your organisation considering large scale infrastructure changes? Dial-A-Geek can assist.
              <br/><br/>Whether it’s new software, network changes or new equipment, Dial-A-Geek has you covered.
              <br/><br/>We assist in purchasing, implementation & we can provide continued support & monitoring.<br/><br/>
              For the past decade Dial-A-Geek has been refining their service offerings aimed at managing Small & Medium sized business networks.
              <br/><br/>With our comprehensive partnerships, we can provide a large array of services including:<br/><br/>
              <b>
              • Helpdesk remote & onsite support<br/>
              • Network solutions<br/>
              • Training<br/>
              • Office 365 administration & support<br/>
              • SharePoint development & support<br/>
              • Application + software development & support<br/>
              • Consulting & project management<br/>
              • Sales<br/></b>
              </p>
            <button onClick={() => window.open(url, '_self')} className='btn2'>Get Support</button>
          </div>
          <div className='right row'>
            <img src='./images/server.png' alt='' className='services5image' />
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default Service5