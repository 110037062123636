import React from "react"
import img from "../images/services.jpg"
import Back from "../common/Back"
import "../home/featured/Featured.css"
import FeaturedCard from "../home/featured/FeaturedCard"
import GoToTop from "../common/GoToTop"


const Services = () => {
  return (
    <>
      <section className='services mb'>
        <Back name='Services' title='Technical Services' cover={img} />
        <div className='featured container'>
        <div className='servicestext'><p>At Dial-A-Geek, we provide expert technical services, consulting & project management <br/><br/>View more details here:</p></div>

          <FeaturedCard />
        </div>
      </section>
      <GoToTop />

    </>
  )
}

export default Services
