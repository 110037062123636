import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function GoToTop() {
  const routePath = useLocation();
  const [navList, setNavList] = useState(false)

  const onTop = () => {
    window.scrollTo(0, 0);
    setNavList(false);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  return null;
}