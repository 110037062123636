import React from "react"
import Heading from "../../common/Heading"
import "./hero.css"
// import { gtag, install } from 'ga-gtag';

// install('AW-377567632');



const Hero = () => {
  const url = 'https://bookit.dialageek.ca/userDashboard/bookNow/';
  return (
    <>
    
      <section className='hero'>
        <div className='newcontainer'>
        <h4 className='softertext'><a href='https://bookit.dialageek.ca/userDashboard/bookNow/'>Sign In</a> for your recent appointments</h4>
          <Heading title='Order Tech Support To Your Home Or Business ' />
          <form className='flex2' onSubmit={() => window.open(url)}>
            <div className='inputbox'>
            <div className='box flex'>
            <div className='locationIcon'>
            <i class="fa-solid fa-location-dot fa-xl"></i>
            </div>
              
            </div>
            </div>
            <button 
            className='btn7'>
              BOOK NOW
            </button>
            <button className='btn8'>
              <i className='fa fa-search'></i>
            </button>
          </form>
        </div>
      </section>
    </>
  )
}

export default Hero
