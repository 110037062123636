import "./App.css"
import Pages from "./components/pages/Pages"
import TagManager from "react-gtm-module"

const TagManagerArgs = {
  gtmId: "GTM-TVBDX7N"
}

TagManager.initialize(TagManagerArgs)
TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    path: "/"
  }
})

function App() {
  return <Pages />
}

export default App
