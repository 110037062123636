import React from "react"
import Back from "../common/Back"
import GoToTop from "../common/GoToTop"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./terms.css"

const Terms = () => {
  const url = 'https://bookit.dialageek.ca/userDashboard/bookNow/';
  return (
    <>
      <section className='terms'>
        <Back name='Terms & Conditions' title='Our Terms' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Copyright & Trademark' subtitle='Please read carefully and ensure you agree to the terms' />
            <p>Unless otherwise specified, all materials appearing on this site, including the text, site design, logos, graphics, icons, and images, as well as the selection, assembly, and arrangement thereof, are the sole property of this Store.
                <br/><br/>Copyright © 2022, ALL RIGHTS RESERVED.</p>
            <p>You may use the content of this site only for the purpose of shopping on this site or placing an order on this site and for no other purpose. No materials from this site may be copied, reproduced, modified, republished, uploaded, posted, transmitted, or distributed in any form or by any means without our prior written permission. All rights not expressly granted herein are reserved.
                Any unauthorized use of the materials appearing on this site may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties. 
                
                <br/><br/><b>Credit Cards</b> </p>
                <p>We accept the following credit cards: Visa and Mastercard. <br/>
                There is no surcharge for using your credit card to make purchases. Please be sure to provide your exact billing address and telephone number (i.e., the address and phone number your credit card bank has on file for you). Incorrect information will cause a delay in processing your order. Your credit card will be billed upon receipt of your order. </p>
                
                <p><br/><b>Booking</b> <br/></p>
                <p>All bookings of Dial-A-Geek technicians are performed through your account at dialageek.ca. Times provided are not a guarantee. Dial-A-Geek reserves the right to cancel any appointment for any reason.<br/>
                </p>

                <p><br/><b>Cancellation</b> <br/></p>
                <p>Any appointment cancelled less than 24 hours prior to the appointment may be subject to a cancellation fee as set by Dial-A-Geek.  <br/>
                </p>

                <p><br/><b>Returns</b> <br/></p>
                <p>All returns are done on a per item basis as set by the manufacturer. Please contact Dial-A-Geek prior to ordering to confirm return policy.  <br/>
                </p>

                <p><br/><b>Links</b> <br/></p>
                <p>This site may contain links to other sites on the Internet that are owned and operated by third parties. You acknowledge the Store is not responsible for the operation of or content located on or through any such site. <br/>
                </p>

                <p><br/><b>Multiple Product Orders</b> <br/></p>
                <p>For a multiple product order, we will make every attempt to ship all products contained in the order at the same time. Products that are unavailable at the time of shipping will be shipped as they become available unless you inform us otherwise.
                You will only be charged for products contained in each shipment, plus any applicable shipping charges. You will only be charged for shipping at the rate quoted to you on your purchase receipt. The entirety of this shipping charge may be applied to the first product(s) shipped on a multiple shipment order. <br/>
                </p>

                <p><br/><b>Order Acceptance Policy</b>  <br/></p>
                <p>Your receipt of an electronic or other form of order confirmation does not signify our acceptance of your order, nor does it constitute confirmation of our offer to sell. The Store reserves the right at any time after receipt of your order to accept or decline your order for any reason or to supply less than the quantity you ordered of any item. <br/>
                </p>

                <p><br/><b>Other Conditions</b> <br/></p>
                <p>These Conditions will supersede any terms and/or conditions you include with any purchase order. We reserve the right to make changes to this site and these Conditions at any time. <br/>
                </p>

                <p><br/><b>Out-of-Stock Products</b> <br/></p>
                <p>We will ship your product as it becomes available. However, there may be times when the product you have ordered is out-of-stock which will delay fulfilling your order. We will keep you informed of any products that you have ordered that are out-of-stock and unavailable for immediate shipment. You may cancel your order at any time prior to decoration of your items. <br/>
                </p>

                <p><br/><b>Privacy</b> <br/></p>
                <p>We keep your personal information private and secure. When you make a purchase from our site, you provide your name, email address, credit card information, address, phone number, and a password.
                  We use this information to process your orders, to keep you updated on your orders and to personalize your shopping experience. Our secure servers protect your information using advanced encryption techniques and firewall technology.
                  To keep you informed about our latest offers, we may notify you of current promotions, specials, and new additions to the Store. You may unsubscribe from our newsletters by following the unsubscribe instructions in any email you receive from us. When entering any of our contests or prize drawings, you provide your name, email address and mailing address. If you win, we will send the prize to the address entered and notify you by email.
                  When you enter a contest or drawing you are also included in our newsletter list to receive notice of promotions, specials, and new additions to the Store.
                  You may unsubscribe from this news list by following the unsubscribe instructions in any email received.
                  We use “cookies” to keep track of your current shopping session to personalize your experience and so that you may retrieve your shopping cart at any time. <br/>
                </p>

                <p><br/><b>Privacy on Other Web Sites</b> <br/></p>
                <p>Other sites accessible through our site have their own privacy policies and data collection practices. Please consult each site’s privacy policy. Our Store is not responsible for the actions of third parties. <br/>
                </p>

                <p><br/><b>Shipping Policy</b>  <br/></p>
                <p>Shipping Time – Please allow 7-10 days for orders to be produced. All online orders will be shipped via the method you select. We cannot guarantee when an order will arrive. Consider any shipping or transit time offered to you by the store or other parties only as an estimate. We encourage you to order in a timely fashion to avoid delays caused by shipping or product availability.
                TRANSIT TIME IS IN ADDITION TO PRODUCTION TIME. <br/>
                </p>

                <p><br/><b>Taxes</b>  <br/></p>
                <p>The Store shall automatically charge the applicable sales taxes (GST/PST/HST) for the Province to which the items will be delivered. <br/>
                </p>

                <p><br/><b>Typographical Errors</b>  <br/></p>
                <p>In the event a product is listed at an incorrect price due to typographical error or error in pricing information received from our suppliers, the Store shall have the right to refuse or cancel any orders placed for product listed at the incorrect price.
                  The Store shall have the right to refuse or cancel any such orders whether the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is canceled, the Store shall immediately issue a credit to your credit card account in the amount of the incorrect price. <br/>
                </p>

            <button onClick={() => window.open(url, '_self')} className='btn2'>Get Support</button>
          </div>
          <div className='right row'>
            {/* <img src='./immio.jpg' alt='' /> */}
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default Terms
